import { Box, Button, Center, Circle, HStack, IconButton, Img, Text, VStack } from "@chakra-ui/react"
import { MainTemplate } from "../../../templates/MainTemplate"
import { Link } from "../../../components/Link"
import { FiCamera, FiCheck, FiChevronLeft, FiEdit, FiEdit2, FiX } from "react-icons/fi"
import { SaveBasicInfoModal } from "../components/SaveBasicInfoModal"
import { GetCreditCardListing, InitializePayoutAccount } from "../../../services/PaymentService"
import { UserContext } from "../../../providers/UserProvider"
import { useContext, useEffect, useRef, useState } from "react"
import { SaveSocialInfoModal } from "../components/SaveSocialInfoModal"
import { UpdateBasicInfo, UpdateShippingAddress } from "../../../services/AccountService"
import { RiBankLine } from "react-icons/ri"
import { AddPaymentCardModal } from "../../../components/AddPaymentCardModal"
import { AssetType, GenerateUploadUrl } from "../../../services/AssetService"
import { SaveCoverPictureDialog } from "../components/SaveCoverPictureDialog"
import { SaveShippingAddressModal } from "../components/SaveShippingAddressModal"
import { PayoutRedirectDialog } from "../components/PayoutRedirectDialog"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"

export const SettingsPage = () => {
    const { user, setUser } = useContext(UserContext)
    const [credit_cards, setCreditCards] = useState<any[]>([])
    const [upload_file_type, setUploadFileType] = useState<AssetType.PROFILE_PICTURE | AssetType.PROFILE_COVER | string>('')
    const upload_file_ref = useRef<any>()
    const { isMobile } = useScreenSize()

    console.log(user, 'user')

    useEffect(() => {
        (async () => {
            const result = await GetCreditCardListing()
            setCreditCards(() => result)
        })();
    }, [])

    const setupPayout = async () => {
        const { stripe_payout_account } = await InitializePayoutAccount('US')
        window.location.href = stripe_payout_account.onboarding_link
    }

    const onSaveBasicInfo = async (basic_info: any) => {
        const result = await UpdateBasicInfo({ ...basic_info })
        setUser(() => result)
    }

    const onSavePaymentCard = (payment_card: any) => {
        setCreditCards(() => [...credit_cards, payment_card])
    }

    const openExternalLink = (url: string) => {
        if (url) {
            window.open(url, '_blank')
        }
    }

    const onFileUpload = async (e: any) => {
        const file = e.target.files.length > 0 ? e.target.files[0] : null
        if (file) {
            let filename = `cover-${new Date().getTime()}.jpg`
            const { url, request_url } = await GenerateUploadUrl(upload_file_type, filename, file)
            await fetch(request_url, {
                method: "PUT",
                headers: {
                    "x-amz-acl": "public-read"
                },
                body: file
            })
            const _user = { ...user }
            if (upload_file_type === AssetType.PROFILE_PICTURE) {
                _user.profile_picture_url = url
            }
            if (upload_file_type === AssetType.PROFILE_COVER) {
                _user.cover_picture_url = url
            }
            const result = await UpdateBasicInfo(_user)
            setUser(() => result)
        }
    }

    const uploadProfilePicture = () => {
        setUploadFileType(() => AssetType.PROFILE_PICTURE)
        upload_file_ref.current.click()
    }

    const uploadCoverPicture = () => {
        setUploadFileType(() => AssetType.PROFILE_COVER)
        upload_file_ref.current.click()
    }

    const onUpdateShippingAddress = async (address: any) => {
        const result = await UpdateShippingAddress(address)
        setUser(() => result)
    }

    return (
        <MainTemplate>
            <VStack w={'full'} align={'center'} spacing={'24px'}>
                <HStack w={'full'} maxW={'600px'} align={'start'}>
                    <Button variant={'outline'} px={'24px'} leftIcon={<FiChevronLeft />}>
                        Back
                    </Button>
                </HStack>
                {user &&
                    <VStack borderRadius={'8px'} py={isMobile ? '16px' : '40px'} spacing={'20px'} bg={'#262333'} w={'full'} maxW={'680px'} minH={'500px'}>
                        <VStack w={'full'} spacing={'16px'}>
                            <HStack px={isMobile ? '16px' : '40px'} w={'full'} justify={'space-between'}>
                                <Text fontSize={'16px'} fontWeight={600}>Profile Picture</Text>
                            </HStack>
                            <Box px={isMobile ? '16px' : '40px'} w={'full'} h={'310px'} pos={'relative'} pb={'16px'}>
                                <Center bg={'#14141f'}
                                    borderRadius={'8px'}
                                    overflow={'hidden'}
                                    pos={'absolute'}
                                    w={'91%'}
                                    maxW={'600px'}
                                    h={'240px'}>
                                    <Img h={'full'} src={user.cover_picture_url ? user.cover_picture_url : 'https://placehold.co/600x240/14141f/14141f.png'} />
                                </Center>
                                <VStack w={'full'} h={'full'} justify={'space-between'} pos={'relative'}>
                                    <HStack w={'full'} justify={'end'} p={'16px'}>
                                        <SaveCoverPictureDialog onUploadCover={uploadCoverPicture} />
                                    </HStack>
                                    <HStack px={'24px'} w={'full'} align={'end'} spacing={'16px'}>
                                        <Img borderRadius={'6px'} boxSize={'130px'} objectFit={'contain'} src={user.profile_picture_url ? user.profile_picture_url : '/assets/images/account/profile-picture-placeholder.png'} />
                                        <VStack align={'start'}>
                                            <HStack spacing={'16px'} w={'full'}>
                                                {isMobile &&
                                                    <IconButton aria-label={'Upload profile picture'}
                                                        _hover={{ bg: '#2F2C3B' }}
                                                        bg={'#2F2C3B'}
                                                        size={'lg'}
                                                        onClick={uploadProfilePicture}
                                                        icon={<FiCamera />}
                                                    />
                                                    ||
                                                    <Button _hover={{ bg: '#2F2C3B' }} bg={'#2F2C3B'} onClick={uploadProfilePicture} leftIcon={<FiCamera />}>
                                                        {user.profile_picture_url && 'Update' || 'Add'} profile picture
                                                    </Button>
                                                }
                                                {user.profile_picture_url &&
                                                    <>
                                                        {isMobile &&
                                                            <IconButton aria-label={'Remove profile picture'}
                                                                size={'lg'}
                                                                _hover={{ bg: '#2F2C3B' }}
                                                                bg={'#2F2C3B'} icon={<FiX />}
                                                            />
                                                            ||
                                                            <Button _hover={{ bg: '#2F2C3B' }} bg={'#2F2C3B'} leftIcon={<FiX />}>Remove</Button>
                                                        }
                                                    </>
                                                }
                                            </HStack>
                                            <Text color={'gray.300'} fontSize={'14px'}>We support PNG, JPEGS. 10mb max.</Text>
                                        </VStack>
                                    </HStack>
                                </VStack>
                            </Box>
                            <Box w={'full'} borderBottomWidth={'1px'} borderBottomColor={'#1F1C2C'} />
                            <VStack w={'full'} px={isMobile ? '16px' : '40px'}>
                                <HStack w={'full'} justify={'space-between'}>
                                    <Text fontSize={'16px'} fontWeight={600}>Basic Information</Text>
                                    <SaveBasicInfoModal onSave={onSaveBasicInfo} />
                                </HStack>
                                <VStack spacing={'2px'} align={'start'} w={'full'}>
                                    <Text color={'gray.200'}>
                                        <Text color={'gray.400'} as={'span'}>Full name: </Text>
                                        {user.firstname} {user.lastname}
                                    </Text>
                                    <Text color={'gray.200'}>
                                        <Text color={'gray.400'} as={'span'}>Email address: </Text>
                                        {user.email}
                                    </Text>
                                </VStack>
                                <VStack align={'start'} w={'full'}>
                                    <Text color={'gray.400'}>Author's Bio</Text>
                                    <Box minH={'20px'} w={'full'}>
                                        <Text>{user.bio ? user.bio : '---'}</Text>
                                    </Box>
                                </VStack>
                            </VStack>
                        </VStack>
                        <Box w={'full'} borderBottomWidth={'1px'} borderBottomColor={'#1F1C2C'} />
                        <VStack w={'full'} spacing={'16px'} px={isMobile ? '16px' : '40px'}>
                            <HStack w={'full'} justify={'space-between'}>
                                <Text fontSize={'16px'} fontWeight={600}>Social Information</Text>
                                <SaveSocialInfoModal onSave={onSaveBasicInfo} />
                            </HStack>
                            <VStack spacing={'12px'} align={'start'} w={'full'}>
                                <Button onClick={() => openExternalLink(user.website_url)} justifyContent={'start'} w={'full'} leftIcon={<Img boxSize={'30px'} objectFit={'contain'} src={'/assets/images/icons/website.png'} />} bg={'#2F2C3B'}>
                                    {user.website_url ? user.website_url : 'Website'}
                                </Button>
                                <Button onClick={() => openExternalLink(user.instagram_url)} justifyContent={'start'} w={'full'} leftIcon={<Img boxSize={'30px'} objectFit={'contain'} src={'/assets/images/icons/instagram.png'} />} bg={'#2F2C3B'}>
                                    {user.instagram_url ? user.instagram_url : 'Instagram'}
                                </Button>
                                <Button onClick={() => openExternalLink(user.twitter_url)} justifyContent={'start'} w={'full'} leftIcon={<Img boxSize={'30px'} objectFit={'contain'} src={'/assets/images/icons/twitter.png'} />} bg={'#2F2C3B'}>
                                    {user.twitter_url ? user.twitter_url : 'Twitter'}
                                </Button>
                                <Button onClick={() => openExternalLink(user.facebook_url)} justifyContent={'start'} w={'full'} leftIcon={<Img boxSize={'30px'} objectFit={'contain'} src={'/assets/images/icons/facebook.png'} />} bg={'#2F2C3B'}>
                                    {user.facebook_url ? user.facebook_url : 'Facebook'}
                                </Button>
                            </VStack>
                        </VStack>
                        <Box w={'full'} borderBottomWidth={'1px'} borderBottomColor={'#1F1C2C'} />
                        <VStack w={'full'} spacing={'16px'} px={isMobile ? '16px' : '40px'}>
                            <HStack w={'full'} justify={'space-between'}>
                                <Text fontSize={'16px'} fontWeight={600}>Preferences</Text>
                                <Link>Edit</Link>
                            </HStack>
                            <VStack spacing={'16px'} align={'start'} w={'full'}>
                                <VStack spacing={'4px'} align={'start'} w={'full'}>
                                    <Text fontSize={'14px'} color={'gray.400'}>Genres</Text>
                                    {(user.preference && user.preference.genres && user.preference.genres.length > 0) &&
                                        <HStack w={'full'}>
                                            {user.preference.genres.map((item: any, index: number) =>
                                                <Button key={index} bg={'#2F2C3B'}>
                                                    {item}
                                                </Button>
                                            )}
                                        </HStack>
                                        ||
                                        <Button bg={'#2F2C3B'}>
                                            Enter preference
                                        </Button>
                                    }
                                </VStack>
                                <VStack spacing={'4px'} align={'start'}>
                                    <Text fontSize={'14px'} color={'gray.400'}>Age range</Text>
                                    {(user.preference && user.preference.age_range && user.preference.age_range.length > 0) &&
                                        <HStack w={'full'}>
                                            {user.preference.age_range.map((item: any, index: number) =>
                                                <Button key={index} bg={'#2F2C3B'}>
                                                    {item}
                                                </Button>
                                            )}
                                        </HStack>
                                        ||
                                        <Button bg={'#2F2C3B'}>
                                            Enter preference
                                        </Button>
                                    }
                                </VStack>
                            </VStack>
                        </VStack>
                        <Box w={'full'} borderBottomWidth={'1px'} borderBottomColor={'#1F1C2C'} />
                        <VStack w={'full'} spacing={'16px'} px={isMobile ? '16px' : '40px'}>
                            <HStack w={'full'} justify={'start'}>
                                <Text fontSize={'16px'} fontWeight={600}>Payment Information</Text>
                            </HStack>
                            <VStack spacing={'16px'} align={'start'} w={'full'}>
                                <VStack spacing={'4px'} align={'start'} w={'full'}>
                                    <HStack w={'full'} justify={'space-between'}>
                                        <Text fontSize={'14px'} color={'gray.400'}>Payout Account</Text>
                                        {(user && user.payout_account) &&
                                            <PayoutRedirectDialog onStart={setupPayout}>
                                                <Link>Edit</Link>
                                            </PayoutRedirectDialog>
                                        }
                                    </HStack>
                                    {(user && user.payout_account) &&
                                        <HStack cursor={'pointer'} spacing={'16px'} borderRadius={'8px'} px={'24px'} py={'13px'} w={'full'} bg={'#2F2C3B'}>
                                            <Center borderRadius={'4px'} w={'52px'} h={'36px'} bg={'white'}>
                                                <RiBankLine color={'#000'} size={'24px'} />
                                            </Center>
                                            <VStack flexGrow={1} align={'start'} spacing={0}>
                                                <Text color={'gray.200'} fontSize={'14px'}>{user.payout_account.bank_name}</Text>
                                                <Text color={'gray.200'} fontSize={'14px'}>*****{user.payout_account.bank_account_number}</Text>
                                            </VStack>
                                        </HStack>
                                        ||
                                        <PayoutRedirectDialog onStart={setupPayout}>
                                            <Button bg={'#2F2C3B'}>
                                                Setup payout account
                                            </Button>
                                        </PayoutRedirectDialog>
                                    }

                                </VStack>
                                <VStack spacing={'4px'} align={'start'} w={'full'}>
                                    <HStack w={'full'} justify={'space-between'}>
                                        <Text fontSize={'14px'} color={'gray.400'}>Shipping address</Text>
                                        {user.shipping_address &&
                                            <Link>Add</Link>
                                        }
                                    </HStack>

                                    {user.shipping_address &&
                                        <HStack cursor={'pointer'} spacing={'16px'} borderRadius={'8px'} px={'24px'} py={'13px'} w={'full'} bg={'#2F2C3B'}>
                                            <Center overflow={'hidden'} borderRadius={'4px'} w={'52px'} h={'36px'} bg={'#ACADBC'}>
                                                <Img w={'100%'} src={'/assets/images/icons/map.png'} />
                                            </Center>
                                            <VStack flexGrow={1} align={'start'} spacing={0}>
                                                <Text color={'gray.300'} fontSize={'14px'} textTransform={'uppercase'}>
                                                    {user.shipping_address.line1}, {user.shipping_address.city},
                                                    {user.shipping_address.state}, {user.shipping_address.country}, {user.shipping_address.postal_code}
                                                </Text>
                                            </VStack>
                                        </HStack>
                                    }
                                    {!user.shipping_address &&
                                        <SaveShippingAddressModal onSave={onUpdateShippingAddress}>
                                            <Button bg={'#2F2C3B'}>
                                                Enter shipping address
                                            </Button>
                                        </SaveShippingAddressModal>
                                    }


                                </VStack>
                                <VStack spacing={'4px'} align={'start'} w={'full'}>
                                    <HStack w={'full'} justify={'space-between'}>
                                        <Text fontSize={'14px'} color={'gray.400'}>Card</Text>
                                        <AddPaymentCardModal onSave={onSavePaymentCard}>
                                            <Link>Add</Link>
                                        </AddPaymentCardModal>
                                    </HStack>

                                    {credit_cards.length > 0 &&
                                        <VStack align={'start'} w={'full'}>
                                            {credit_cards.map((item: any, index: number) =>
                                                <HStack key={index} cursor={'pointer'} spacing={'16px'} borderRadius={'8px'} px={'24px'} py={'13px'} w={'full'} bg={'#2F2C3B'}>
                                                    <Center borderRadius={'4px'} w={'52px'} h={'36px'} bg={'white'}>
                                                        <Img h={'100%'} src={'https://img.icons8.com/color-glass/48/visa.png'} />
                                                    </Center>
                                                    <VStack flexGrow={1} align={'start'} spacing={0}>
                                                        <Text color={'gray.300'} fontSize={'14px'}>**** **** **** **** {item.last4}</Text>
                                                        <Text color={'gray.300'} fontSize={'14px'}>{('0' + item.exp_month).slice(-2)}/{('0' + item.exp_year).slice(-2)}</Text>
                                                    </VStack>
                                                    <HStack spacing={'16px'}>
                                                        <Text color={'gray.400'} fontSize={'16px'}>Set as default</Text>
                                                        <Text color={'gray.200'}>Remove</Text>
                                                    </HStack>
                                                </HStack>
                                            )}
                                        </VStack>
                                    }

                                    {credit_cards.length === 0 &&
                                        <AddPaymentCardModal onSave={onSavePaymentCard}>
                                            <Button bg={'#2F2C3B'}>
                                                Enter card information
                                            </Button>
                                        </AddPaymentCardModal>
                                    }
                                </VStack>
                            </VStack>
                        </VStack>
                        <Box w={'full'} borderBottomWidth={'1px'} borderBottomColor={'#1F1C2C'} />
                        <VStack w={'full'} spacing={'16px'} px={isMobile ? '16px' : '40px'}>
                            <VStack w={'full'} align={'start'} spacing={0}>
                                <Text fontSize={'16px'} fontWeight={600}>Deactivate account</Text>
                                <Text fontSize={'14px'} color={'gray.400'}>You are allowed to deactivate or delete your account whenever necessary.</Text>
                            </VStack>
                            <HStack w={'full'} spacing={'16px'}>
                                <Button bg={'#2F2C3B'} flexGrow={1}>
                                    Deactivate account
                                </Button>
                                <Button bg={'#2F2C3B'} flexGrow={1}>
                                    Delete my account
                                </Button>
                            </HStack>
                        </VStack>
                    </VStack>
                }
            </VStack>
            <input hidden={true} type={'file'} ref={upload_file_ref} onChange={onFileUpload} accept="image/*" />
        </MainTemplate>
    )
}