import { Box, Button, Center, Circle, Divider, HStack, IconButton, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { BiCheck } from "react-icons/bi";
import { BsCheck } from "react-icons/bs";
import { FiCheck, FiChevronDown, FiSearch, FiX } from "react-icons/fi";
import { findIndex, findWhere } from "underscore";
import { useDebounce } from 'use-debounce';

interface OptionProps {
    label: string;
    subLabel?: string;
    value: string | any;
}

interface SelectProps {
    options: OptionProps[];
    value?: OptionProps[];
    setValue?: (value: OptionProps[]) => void;
    placeholder?: string;
    subPlaceholder?: string;
    offset?: [number, number];
    width?: string;
    withSearch?: boolean;
    maxDisplay?: number;
}

const option_style: any = {
    bg: '#262333',
    size: '16px',
    borderWidth: '1px',
    borderColor: '#2F2C3B'
}

const active_option_style: any = {
    ...option_style,
    bg: 'green.200',
    borderWidth: '0px'
}

export const MultipleSelect = ({
    options,
    value,
    setValue,
    placeholder = 'Select options',
    subPlaceholder = placeholder,
    offset = [12, 12],
    width = '285px',
    withSearch,
    maxDisplay = 10
}: SelectProps) => {

    const [keyword, setKeyword] = useState<string>('')
    // const [debounced_keyword] = useDebounce(keyword, 1000);
    const [local_options, setLocalOptions] = useState<OptionProps[]>([...options])
    const [selected_options, setSelectedOptions] = useState<OptionProps[]>([])
    const [is_adding, setIsAdding] = useState<boolean>(false)
    const {isOpen, onClose, onOpen} = useDisclosure()

    const selectOption = (option: OptionProps) => {
        const index = findIndex(selected_options, item => item.value === option.value)
        if (index > -1) {
            setSelectedOptions(() => [...selected_options.filter(item => item.value !== option.value)])
        } else {
            setSelectedOptions(() => [...selected_options, option])
        }
    }

    const changeValue = () => {
        if (setValue) {
            setValue([...selected_options])
        }
        onClose()
    }

    const add = () => {
        setIsAdding(()=> true)
        const value = {
            label: keyword,
            value: keyword.toUpperCase().replaceAll(' ', '_')
        }
        setLocalOptions(()=> [value, ...local_options])
        setSelectedOptions(()=> [...selected_options, value])
        setKeyword(() => '')
        setIsAdding(()=> false)
    }

    return (
        <Menu isOpen={isOpen} isLazy={true} offset={offset} placement={'top'} closeOnSelect={false}>
            <MenuButton onClick={onOpen} color={value ? 'white' : 'gray.400'} fontWeight={400} textAlign={'left'} w={'full'} as={Button} rightIcon={<FiChevronDown />}>
                {placeholder}
            </MenuButton>
            <MenuList bg={'#1F1C2C'} w={width}>
                <HStack px={'12px'} justify={'space-between'}>
                    <Text fontWeight={600}>{subPlaceholder}</Text>
                    <IconButton onClick={onClose} size={'sm'} aria-label={'Close'} icon={<FiX />} />
                </HStack>
                <Box py={'8px'}>
                    {withSearch ?
                        <InputGroup>
                            <Input value={keyword} onChange={e => setKeyword(e.target.value)} borderRadius={0} variant={'filled'} type='text' placeholder='Search' />
                            <InputRightElement>
                                <FiSearch />
                            </InputRightElement>
                        </InputGroup>
                        :
                        <Divider />
                    }
                </Box>
                {keyword &&
                    <MenuItem justify={'space-between'} as={HStack} w={'full'} bg={'#1F1C2C'}>
                        <Text textTransform={'capitalize'}>{keyword}</Text>
                        <Button onClick={add} size={'sm'} isLoading={is_adding} loadingText={'Adding...'}>Add</Button>
                    </MenuItem>
                }
                <Box maxH={'300px'} overflowY={'auto'}>
                    {local_options.map((item: any, index: number) =>
                        <MenuItem onClick={() => selectOption(item)} key={index} py={'12px'} cursor={'pointer'} alignItems={'center'} as={HStack} w={'full'} justify={'space-between'} bg={'#1F1C2C'}>
                            <VStack spacing={0} align={'start'}>
                                <Text>{item.label}</Text>
                                {item.subLabel &&
                                    <Text color={'gray.400'} fontSize={'12px'}>{item.subLabel}</Text>
                                }
                            </VStack>
                            <Center w={'16px'} h={'16px'} borderRadius={'4px'} {...findWhere(selected_options, { value: item.value }) ? active_option_style : option_style}>
                                {findWhere(selected_options, { value: item.value }) &&
                                    <BiCheck color={'#276749'} />
                                }
                            </Center>
                        </MenuItem>
                    )}
                </Box>
                <HStack pt={'8px'} pr={'8px'} justify={'end'} w={'full'}>
                    <Button onClick={changeValue} size={'sm'}>Done</Button>
                </HStack>
            </MenuList>

        </Menu>
    )
}