import { Box, Button, Checkbox, Divider, HStack, Img, Text, VStack } from "@chakra-ui/react"
import { FiChevronLeft, FiTrash } from "react-icons/fi"
import { MainTemplate } from "../../../templates/MainTemplate"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"
import { Fragment, useEffect, useState } from "react"
import { GetCartContents, RemoveFromCart } from "../../../services/UserService"
import { pluck, reduce } from "underscore"
import { useNavigate } from "react-router-dom"
import { CreditCardPaymentModal } from "../../../components/CreditCardPaymentModal"

export const CartPage = () => {
    const { isMobile } = useScreenSize()
    const navigate = useNavigate()
    const [cart_contents, setCartContents] = useState<any[]>([])
    const [selected_contents, setSelectedContents] = useState<any[]>([])

    useEffect(() => {
        (async () => {
            const result = await GetCartContents()
            const _result = [...result.map((item: any) => ({ ...item, is_checked: true }))]
            setSelectedContents(()=> _result.filter(item=>item.is_checked))
            setCartContents(() => _result)
        })()
    }, [])

    const toggle = (index: number) => {
        const _cart_contents = [...cart_contents]
        const item = _cart_contents[index]
        _cart_contents[index].is_checked = !item.is_checked

        setSelectedContents(()=> _cart_contents.filter(item=>item.is_checked))
        setCartContents(() => _cart_contents)
    }

    const getSubTotal = () => {
        let sum = 0
        for(let item of cart_contents){
            if(item.is_checked && item.is_paid){
                sum += item.price
            }
        }
        return sum.toFixed(2)
    }

    const remove = async (item: any, index: number)=> {
        try{
            await RemoveFromCart(item.id)
            const _cart_contents = cart_contents.filter(_item=> _item.id !== item.id)
            setCartContents(() => [..._cart_contents])
        }catch(e){

        }
    }

    const getSubContentIds = ()=> {
        const sub_content_ids: Array<number> = []
        for(let item of cart_contents){
            if(item.is_checked && item.is_paid){
                sub_content_ids.push(item.sub_contents[0].id)
            }
        }
        return sub_content_ids
    }

    return (
        <MainTemplate>
            <VStack w={'full'} align={'center'} spacing={'24px'}>
                <HStack w={'full'} maxW={'1300px'} align={'start'}>
                    <Button variant={'outline'} px={'24px'} leftIcon={<FiChevronLeft />}>
                        Back
                    </Button>
                </HStack>
                <HStack align={'start'} w={'full'} maxW={'1316px'} gap={'16px'}>
                    <VStack h={'full'} justify={'start'} borderRadius={'8px'} py={isMobile ? '16px' : '40px'} spacing={'24px'} bg={'#262333'} minH={'500px'} w={'full'} maxW={'800px'}>
                        <HStack w={'full'} px={'24px'}>
                            <Text fontSize={'24px'} color={'gray.300'}>My Cart</Text>
                        </HStack>
                        <Box w={'full'} h={'1px'} bg={'gray.800'} />
                        <VStack gap={'24px'} align={'start'} w={'full'}>
                            {cart_contents.map((item, index) =>
                                <Fragment key={index}>
                                    <HStack px={'24px'} w={'full'} justify={'space-between'}>
                                        <HStack w={'fit-content'} gap={'16px'}>
                                            <Box w={'fit-content'} h={'fit-content'} pos={'relative'}>
                                                <Checkbox onChange={() => toggle(index)} isChecked={item.is_checked} borderColor={'gray.50'} bg={'gray.700'} size={'lg'} pos={'absolute'} left={'4px'} top={'4px'} colorScheme={'green'} />
                                                <Img bg={'gray.800'} borderRadius={'8px'} w={'85px'} h={'85px'} objectFit={'contain'} src={item.thumbnail_url} />
                                            </Box>
                                            <VStack spacing={0} align={'start'}>
                                                <Text>{item.title}</Text>
                                                {item.is_paid &&
                                                    <Text>${item.price}</Text>
                                                }
                                                {!item.is_paid &&
                                                    <Text>Free</Text>
                                                }
                                            </VStack>
                                        </HStack>
                                        <Button onClick={()=> remove(item, index)} color={'green.300'} leftIcon={<FiTrash />}>Remove</Button>
                                    </HStack>
                                    <Box w={'full'} h={'1px'} bg={'gray.800'} />

                                </Fragment>

                            )}
                        </VStack>
                    </VStack>
                    <VStack h={'full'} justify={'start'} borderRadius={'8px'} py={isMobile ? '16px' : '40px'} spacing={'24px'} bg={'#262333'} minH={'400px'} w={'full'} maxW={'500px'}>
                        <HStack w={'full'} px={'24px'}>
                            <Text fontSize={'24px'} color={'gray.300'}>Purchase summary</Text>
                        </HStack>
                        <Box w={'full'} h={'1px'} bg={'gray.800'} />
                        <VStack gap={'16px'} px={'24px'} w={'full'} align={'start'}>
                            <HStack w={'full'} justify={'space-between'}>
                                <Text>Subtotal</Text>
                                <Text>$ {getSubTotal()}</Text>
                            </HStack>
                            <HStack w={'full'} justify={'space-between'}>
                                <Text>Discount</Text>
                                <Text>$ 0.00</Text>
                            </HStack>
                            <HStack w={'full'} justify={'space-between'}>
                                <Text>Tax</Text>
                                <Text>$ 0.00</Text>
                            </HStack>
                        </VStack>
                        <Box w={'full'} h={'1px'} bg={'gray.800'} />
                        <VStack gap={'16px'} px={'24px'} w={'full'} align={'start'}>
                            <HStack w={'full'} justify={'space-between'}>
                                <Text>Purchase Total</Text>
                                <Text>$ {getSubTotal()}</Text>
                            </HStack>
                            <VStack pt={'24px'} w={'full'} spacing={'16px'}>
                                <CreditCardPaymentModal label="Proceed to Pay" size={'md'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'} w={'full'} subContentIds={pluck(selected_contents, 'id')} amount={parseFloat(getSubTotal())} />
                                <Button onClick={()=> navigate('/')} color={'green.300'} w={'full'}>Continue exploring</Button>
                            </VStack>
                        </VStack>
                    </VStack>
                </HStack>
            </VStack>
        </MainTemplate>
    )
}