import { Button, Center, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { useContext, useState } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { FiCheck, FiCheckCircle } from "react-icons/fi";
import { FaCheckCircle, FaRegCheckCircle } from "react-icons/fa";
import { RequestPayout } from "../../../services/PaymentService";

interface RequestPayoutModalProps {
    onSendRequest: (amount: number) => void;
}

export const RequestPayoutModal = ({ onSendRequest }: RequestPayoutModalProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { user } = useContext(UserContext)
    const [amount, setAmount] = useState<number>(0)
    const [error, setError] = useState<string>()
    const [is_payout_sent, setIsPayoutSent] = useState<boolean>(false)

    const sendRequest = async () => {
        await RequestPayout(amount)
        if (onSendRequest) {
            onSendRequest(amount)
        }
        setIsPayoutSent(()=> true)
    }

    const changeAmount = (amount: number) => {
        setAmount(() => amount)
        let balance = 0
        if(user.earnings){
            balance = user.earnings
        }
        if (amount > user.earnings) {
            setError(() => `You can't request to payout an amount more than the total earnings from your wallet`)
        } else {
            setError(() => ``)
        }
    }

    return (
        <>
            <Button color={'green.400'} onClick={onOpen}>Request Payout</Button>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                { !is_payout_sent &&
                    <ModalContent bg={'#1F1C2C'}>
                        <ModalHeader>Payout</ModalHeader>
                        <ModalBody>
                            <VStack w={'full'} align={'start'} spacing={'16px'}>
                                <VStack w={'full'} spacing={'8px'} align={'start'}>
                                    <Text>Amount</Text>
                                    <Input variant={'filled'} placeholder={'Enter amount to withdraw'} type={'number'} value={amount} onChange={e => changeAmount(parseFloat(e.target.value))} />
                                </VStack>
                                {error &&
                                    <Center borderRadius={'8px'} p={'16px'} bg={'#262333'}>
                                        <Text fontSize={'16px'} color={'orange.400'}>
                                            {error}
                                        </Text>
                                    </Center>
                                }
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <HStack w={'full'}>
                                <Button flexGrow={1} color={'green.400'} onClick={sendRequest}>
                                    Cancel
                                </Button>
                                <Button isDisabled={(error || amount < 1) ? true : false} flexGrow={1} bg={'green.400'} colorScheme={'green'} onClick={sendRequest}>
                                    Proceed
                                </Button>
                            </HStack>
                        </ModalFooter>
                    </ModalContent>
                    ||
                    <ModalContent bg={'#1F1C2C'} py={'32px'}>
                        <ModalBody>
                            <VStack spacing={'32px'}>
                                <FaRegCheckCircle color={'#2EA652'} size={'80px'} />
                                <VStack>
                                    <Text fontSize={'24px'} fontWeight={600}>Payment Successful</Text>
                                    <Text fontWeight={400} fontSize={'14px'} textAlign={'center'}>
                                        You have successfully sent a payout request of  ${amount}. Your request is pending approval. It would processed within 48hrs
                                    </Text>
                                </VStack>
                                <Button onClick={onClose} w={'full'} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'}>
                                    Proceed
                                </Button>
                            </VStack>
                        </ModalBody>
                    </ModalContent>
                }
                
            </Modal>
        </>
    )
}