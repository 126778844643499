import { Box, HStack, IconButton, Stack, useDisclosure } from "@chakra-ui/react";
import { RefObject, useContext, useEffect, useMemo, useRef, useState } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { LazyLoadImage, trackWindowScroll } from "react-lazy-load-image-component";
import { useOnScreen } from "../../../providers/ScreenProvider";
import { LoginModal } from "../../../components/LoginModal";
import { UserContext } from "../../../providers/UserProvider";
import { PurchaseContentModal } from "./PurchaseContentModal";

interface ContentReaderProps {
    pages: string[];
    scrollPosition: any;
    content: any;
    layout?: 'HORIZONTAL' | 'VERTICAL' | 'SINGLE_PAGING' | 'DOUBLE_PAGING' | string;
    isBought?: boolean;
}

const ContentReader = ({ content, pages, layout = 'VERTICAL', scrollPosition, isBought: is_bought }: ContentReaderProps) => {
    const image_container = useRef<any>()
    const end_box_ref = useRef<any>()
    const is_visible = useOnScreen(end_box_ref)
    const modal_disclosure = useDisclosure()
    const [counter, setCounter] = useState<number>(0)
    const { user, isReady } = useContext(UserContext)
    

    useEffect(()=> {
        if(counter > 1){
            modal_disclosure.onOpen()
        }
    }, [counter])

    useEffect(()=> {
        if(is_visible){
            setCounter(()=> counter + 1)
        }
    }, [is_visible])

    const next = () => {
        image_container.current.scrollLeft += 1340
    }

    const prev = () => {
        image_container.current.scrollLeft -= 1340
    }

    return (
        <Box pos={'relative'}>
            {['DOUBLE_PAGING', 'SINGLE_PAGING'].indexOf(layout) > -1 &&
                <HStack left={'-1%'} top={'300px'} pos={'absolute'} w={'102%'} justify={'space-between'}>
                    <IconButton onClick={prev} colorScheme="gray" aria-label={'Prev'} icon={<FiChevronLeft />} />
                    <IconButton onClick={next} aria-label={'Next'} icon={<FiChevronRight />} />
                </HStack>
            }

            <Box ref={image_container} overflowX={layout === 'HORIZONTAL' ? 'scroll' : 'hidden'} w={'full'} maxW={'1340px'}>
                <Stack minH={'400px'} direction={['HORIZONTAL', 'DOUBLE_PAGING', 'SINGLE_PAGING'].indexOf(layout) > -1 ? 'row' : 'column'} spacing={0}>
                    {pages.map((item: string, index: number) =>
                        <LazyLoadImage
                            width={['HORIZONTAL', 'DOUBLE_PAGING'].indexOf(layout) > -1 ? '50%' : '100%'}
                            key={index}
                            scrollPosition={scrollPosition}
                            src={item} />
                    )}
                    <Box ref={end_box_ref} />
                </Stack>
            </Box>
            { (isReady && !user) &&
                <LoginModal disclosure={modal_disclosure} />
            }
            { (isReady && user && !is_bought) &&
                <PurchaseContentModal content={content} disclosure={modal_disclosure} />
            }
            
        </Box>
    )
}

export default trackWindowScroll(ContentReader)