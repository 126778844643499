import { Button, ButtonProps, Divider, Heading, HStack, IconButton, Text, VStack } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { FiX } from "react-icons/fi"
import { AudienceType } from "../../contents/pages/SaveReaderSettingsPage"
import { filter, findIndex, findWhere } from "underscore"
import { GetGenreListing, GetTagListing } from "../../../services/TagService"

interface ExploreFilterProps {
    filters?: any[];
    setFilters?: (filters: any[]) => void;
    hasGenres?: boolean;
}

const active_filter_style: ButtonProps = {
    borderWidth: '1px',
    borderColor: 'gray.50'
}

export const ExploreFilter = ({ filters = [], setFilters, hasGenres }: ExploreFilterProps) => {
    const [genres, setGenres] = useState<any[]>([])
    const [tags, setTags] = useState<any[]>([])

    const audiences = [
        {
            label: 'Kids',
            code: AudienceType.KIDS
        },
        {
            label: 'Teenagers',
            code: AudienceType.TEENS
        },
        {
            label: 'Adults',
            code: AudienceType.ADULTS
        }
    ]

    const prices = [
        {
            label: 'Free',
            code: 'FREE',
        },
        {
            label: 'Paid',
            code: 'PAID',
        }
    ]

    useEffect(() => {
        (async () => {
            const result = await GetGenreListing()
            setGenres(() => result)
        })();
        (async () => {
            const result = await GetTagListing()
            setTags(() => result)
        })();
    }, [])

    const changeFilters = (type: string, value: string)=> {
        if(setFilters){
            const _filters = [...filters]
            const index = findIndex(_filters, item=> item.type === type && item.value === value)
            if(index > -1){
                _filters.splice(index, 1)
            }else{
                _filters.push({type, value})
            }
            setFilters([..._filters])
        }
    }

    const inFilters = (type: string, value: string)=> {
        const exist = findWhere(filters, {type, value})
        return exist ? true : false
    }

    return (
        <VStack w={'320px'} spacing={'24px'} align={'start'}>
            <HStack justify={'space-between'} w={'full'}>
                <Text fontSize={'24px'} fontWeight={600}>Filters</Text>
                <IconButton aria-label={'close'} icon={<FiX />} />
            </HStack>
            <Divider />
            { hasGenres &&
                <>
                    <VStack align={'start'} spacing={'8px'} w={'full'}>
                        <Text fontSize={'16px'} fontWeight={500} color={'gray.400'}>Genre</Text>
                        <HStack spacing={'8px'} flexWrap={'wrap'} w={'full'}>
                            {genres.map((item: any, index: number) =>
                                <Button bg={'#262333'} {...inFilters('genre', item.code) ? active_filter_style: {}} onClick={()=> changeFilters('genre', item.code)} key={index}>{item.name}</Button>
                            )}
                        </HStack>
                    </VStack>
                    <Divider />
                </>
            }
            <VStack align={'start'} spacing={'8px'} w={'full'}>
                <Text fontSize={'16px'} fontWeight={500} color={'gray.400'}>Tags</Text>
                <HStack spacing={'8px'} flexWrap={'wrap'} w={'full'}>
                    {tags.map((item: any, index: number) =>
                        <Button bg={'#262333'} {...inFilters('tag', item.code) ? active_filter_style: {}} onClick={()=> changeFilters('tag', item.code)} key={index}>{item.name}</Button>
                    )}
                </HStack>
            </VStack>
            <Divider />
            <VStack align={'start'} spacing={'8px'} w={'full'}>
                <Text fontSize={'16px'} fontWeight={500} color={'gray.400'}>Engagement</Text>
                <HStack spacing={'8px'} flexWrap={'wrap'} w={'full'}>
                    {audiences.map((item: any, index: number) =>
                        <Button {...inFilters('audience_type', item.code) ? active_filter_style: {}} onClick={()=> changeFilters('audience_type', item.code)}  key={index}>{item.label}</Button>
                    )}
                </HStack>
            </VStack>
            <VStack align={'start'} spacing={'8px'} w={'full'}>
                <Text fontSize={'16px'} fontWeight={500} color={'gray.400'}>Availability</Text>
                <HStack spacing={'8px'} flexWrap={'wrap'} w={'full'}>
                    {prices.map((item: any, index: number) =>
                        <Button {...inFilters('prices', item.code) ? active_filter_style: {}} onClick={()=> changeFilters('prices', item.code)} key={index}>{item.label}</Button>
                    )}
                </HStack>
            </VStack>
        </VStack>
    )
}