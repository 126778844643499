import { Avatar, Box, HStack, Text, VStack } from "@chakra-ui/react"

interface CreatorCardProps {
    size?: 'sm' | 'md' | 'lg'
    user: any
}

const container_dimentions: any = {
    md: {
        w: '190px',
        h: '190px'
    },
    lg: {
        w: '400px',
        h: '400px'
    }
}

const picture_sizes: any = {
    lg: '2xl',
    md: 'xl'
}

const gaps: any = {
    lg: '24px',
    md: '8px'
}

const name_font_sizes: any = {
    lg: 'md',
    md: 'xs'
}

const stats_font_sizes: any = {
    lg: 'sm',
    md: '2xs'
}

const stats_gaps: any = {
    lg: '16px',
    md: '8px'

}
const stats_vertical_gaps: any = {
    lg: '8px',
    md: '4px'
}

export const CreatorCard = ({ size = 'md', user }: CreatorCardProps) => {
    return (
        <VStack borderRadius={'16px'} bg={'#344054'} justify={'center'} spacing={gaps[size]} {...container_dimentions[size]}>
            <Avatar name={`${user.firstname} ${user.lastname}`} src={user.profile_picture_url} size={picture_sizes[size]} />
            <Text fontSize={name_font_sizes[size]}>{`${user.firstname} ${user.lastname}`}</Text>
            <HStack spacing={stats_gaps[size]}>
                <VStack spacing={stats_vertical_gaps[size]}>
                    <Text fontSize={stats_font_sizes[size]} color={'gray.400'}>Total Comics</Text>
                    <Text fontSize={stats_font_sizes[size]} color={'gray.300'}>{user.total_contents_count}</Text>
                </VStack>
                <VStack spacing={stats_vertical_gaps[size]}>
                    <Text fontSize={stats_font_sizes[size]} color={'gray.400'}>Single Title</Text>
                    <Text fontSize={stats_font_sizes[size]} color={'gray.300'}>{user.single_contents_count}</Text>
                </VStack>
                <VStack spacing={stats_vertical_gaps[size]}>
                    <Text fontSize={stats_font_sizes[size]} color={'gray.400'}>Series</Text>
                    <Text fontSize={stats_font_sizes[size]} color={'gray.300'}>{user.series_contents_count}</Text>
                </VStack>
            </HStack>
        </VStack>
    )
}