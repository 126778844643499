import { useContext } from "react"
import { UserContext } from "../../../providers/UserProvider"
import { MainTemplate } from "../../../templates/MainTemplate"
import { Button, Text, VStack } from "@chakra-ui/react"
import { RequestPayoutModal } from "../components/RequestPayoutModal"
import { RequestPayout } from "../../../services/PaymentService"

export const PayoutPage = ()=> {
    const { user, setUser } = useContext(UserContext)

    const sendPayoutRequest = async (amount: number)=> {
        const result = await RequestPayout(amount)
        setUser(()=> result)
        alert(`Payout successfully requested`)
    }

    return (
        <MainTemplate>
            <VStack w={'full'} align={'start'}>
                <VStack  align={'start'}>
                    <Text>Earnings</Text>
                    <Text fontSize={'24px'} fontWeight={600}>${ (user && user.earnings) ?  user.earnings : 0 }</Text>
                </VStack>
                <RequestPayoutModal onSendRequest={sendPayoutRequest} />
            </VStack>
        </MainTemplate>
    )
}