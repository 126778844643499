import { Button, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack } from "@chakra-ui/react";
import { useScreenSize } from "../../../providers/ScreenSizeProvider";
import { useNavigate } from "react-router-dom";
import { CreditCardPaymentModal } from "../../../components/CreditCardPaymentModal";
import { useState } from "react";

interface PurchaseContentModalProps {
    disclosure: any;
    content: any;
}

export const PurchaseContentModal = ({ content, disclosure }: PurchaseContentModalProps) => {
    const { isOpen, onClose } = disclosure
    const [is_purchase, setIsPurchase] = useState<boolean>()
    const { isMobile } = useScreenSize()
    const navigate = useNavigate()

    return (
        <>
            <Modal size={isMobile ? 'full' : 'lg'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />

                <ModalContent py={'24px'} bg={'#262333'}>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={'32px'} align={'start'} w={'full'}>
                            <VStack spacing={'20px'} w={'full'} align={'start'}>
                                <Img h={'50px'} cursor={'pointer'} onClick={() => navigate(`/`)} src={'/assets/images/logo-vertical.png'} />
                                <VStack spacing={0} align={'start'}>
                                    <Text color={'gray.300'} fontWeight={600} fontSize={'2xl'}>Continue the Adventure!</Text>
                                    <Text color={'gray.300'} fontWeight={400} fontSize={'14px'}>You've reached the end of the free issues. To keep reading, please purchase the next issue.</Text>
                                </VStack>
                                <CreditCardPaymentModal
                                    subContentIds={[content.sub_contents[0].id as number]}
                                    amount={content.price as number}
                                    mt={'8px'} 
                                    size={'md'}
                                    _hover={{ bg: 'green.300' }} 
                                    w={'full'} 
                                    bg={'green.400'} 
                                    color={'black'}
                                    />
                            </VStack>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}