export interface RegisterReqProps {
    firstname: string;
    lastname: string;
    email: string;
    password: string;
}

export const Register = async (user: RegisterReqProps) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message, {cause: result.type})
    }
}

export const Verify = async (token: string, code: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/verify`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            token,
            code
        }),
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message, { cause: result.type})
    }
}

export const VerificationResend = async (token: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/verify/resend`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            verification_request_token: token
        }),
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message, { cause: result.type})
    }
}

export const Login = async (email: string, password: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email,
            password
        }),
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const LoginWithFacebook = async (access_token: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login/facebook`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            access_token
        }),
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const LoginWithGoogle = async (access_token: string)=> {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login/google`, {
        method: "POST",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            access_token
        }),
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}