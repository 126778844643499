import { Center, Image, StackProps, Text, VStack } from "@chakra-ui/react"
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { useDropzone } from 'react-dropzone'
import { FiImage } from "react-icons/fi"
import { Bars } from 'react-loading-icons'
import { AssetReqProps, AssetType, GenerateUploadUrl, Save } from "../../../services/AssetService"
import { ContentAssetProps } from "../../../services/ContentService"
import { BsFolderPlus, BsLink45Deg } from 'react-icons/bs'

interface UploadAssetBoxProps extends StackProps {
    asset?: ContentAssetProps;
    type?: AssetType;
    setAsset?: Dispatch<SetStateAction<ContentAssetProps>> | ((file: ContentAssetProps) => void);
}

export const UploadAssetBox = ({ asset, type = AssetType.CONTENT_COVER, setAsset, ...rest }: UploadAssetBoxProps) => {
    const [is_uploading, setIsUploading] = useState<boolean>()

    useEffect(()=> {
        console.log(asset, 'file')
    }, [asset])

    const onDrop = useCallback((files: Array<File>) => {
        (async () => {
            setIsUploading(() => true)
            try {
                for(let file of files){
                    let filename = `cover-${new Date().getTime()}.jpg`
                    if(type===AssetType.CONTENT){
                        filename = file.name
                    }
                    const { url, request_url } = await GenerateUploadUrl(type, filename, file)
                    await fetch(request_url, {
                        method: "PUT",
                        headers: {
                            "x-amz-acl": "public-read"
                        },
                        body: file
                    })
                    const _file: AssetReqProps = { asset_type: type, url, filename, size: Math.round(file.size / 1024)}
                    const __file = await Save(_file)
                    if (setAsset) {
                        setAsset(__file)
                    }
                }
            } catch (e) {
                console.log(e, 'error')
            }
            setIsUploading(() => false)
        })()

    }, [])

    const getAcceptTypes = ()=> {
        if(type===AssetType.CONTENT_COVER || type===AssetType.PROFILE_PICTURE){
            return {
                'image/png': ['.png'],
                'image/jpeg': ['.jpg', '.jpeg']
            }
        }else{
            return {
                'application/pdf': ['.pdf']
            }
        }
    }

    const { getRootProps, getInputProps } = useDropzone({ 
        onDrop, 
        multiple: type===AssetType.CONTENT ? true : false, 
        accept: getAcceptTypes() as any
    })

    

    return (
        <VStack overflow={'hidden'} cursor={'pointer'} bg={'#353444'} borderRadius={'8px'} justify={'center'} spacing={'10px'} {...getRootProps()} {...rest}>
            {(!is_uploading && asset) &&
                <Image src={asset.url} />
            }
            {(!is_uploading && !asset) &&
                <VStack spacing={'10px'} justify={'center'} w={'full'}>
                    <Center w={'60px'} h={'60px'} bg={'#3D3C4B'} borderRadius={'8px'}>
                        { type === AssetType.CONTENT_COVER &&
                            <FiImage size={'24px'} />
                        }
                        { type === AssetType.CONTENT &&
                            <BsFolderPlus size={'24px'} />
                        }
                        
                    </Center>
                    { type===AssetType.CONTENT_COVER &&
                        <Text fontSize={'14px'} color={'gray.400'}>
                            Supports <Text color={'gray.200'} as={'span'}>png, jpg</Text>. 
                            Size: <Text color={'gray.200'} as={'span'}>10mb max</Text>
                        </Text>
                    }
                    { type===AssetType.CONTENT &&
                        <Text fontSize={'14px'} color={'gray.400'}>
                            Supports <Text color={'gray.200'} as={'span'}>pdf</Text>. 
                            Size: <Text color={'gray.200'} as={'span'}>10gb max</Text>
                        </Text>
                    }
                    <Text fontSize={'14px'} color={'gray.300'}>Drag your files here or click to upload</Text>
                </VStack>
            }
            {is_uploading &&
                <VStack spacing={'10px'} justify={'center'}  w={'full'}>
                    <Center w={'60px'} h={'60px'} bg={'#3D3C4B'} borderRadius={'8px'}>
                        <Bars width={'40px'} height={'20px'} />
                    </Center>
                    <Text fontSize={'14px'} color={'gray.400'}>Uploading, please wait...</Text>
                </VStack>
            }
            <input {...getInputProps()} />

        </VStack>
    )
}