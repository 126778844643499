import { Storage } from '@ionic/storage'

const store = new Storage({
    name: process.env.REACT_APP_STORAGE_NAME
})

export const GetUnverified = async (token: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/unverified/${token}`, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const GetCurrent = async () => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/current`, {
        method: "GET",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            'Content-Type': 'application/json',
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const GetTopCreators = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/creators/listing`, {
        method: "GET",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        }
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const AddToCart = async (content_id: number) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/carts/contents/add`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            content_id
        }),
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}
export const RemoveFromCart = async (content_id: number) => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/carts/contents/remove`, {
        method: "POST",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            content_id
        }),
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}

export const GetCartContents = async () => {
    const storage = await store.create();
    const access_token = await storage.get(`access_token`)
    const response = await fetch(`${process.env.REACT_APP_API_URL}/users/carts/contents`, {
        method: "GET",
        credentials: "include",
        headers: {
            'Authorization': `Bearer ${access_token}`,
            "Content-Type": "application/json",
        },
    })
    const result = await response.json()
    if (response.status === 200) {
        return result
    } else {
        throw new Error(result.message)
    }
}