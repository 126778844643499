import { Button, ButtonProps, Center, Circle, HStack, Img, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Radio, RadioGroup, Text, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useContext, useEffect, useState } from "react";
import { RiSecurePaymentLine } from "react-icons/ri";
import { UserContext } from "../providers/UserProvider";
import { Confirm, GetCreditCardListing, Initialize, InitializeAccount } from "../services/PaymentService";
import { Select } from "./Select";
import { Country, State } from '@flowko/countries-cities-list';
import { findWhere } from "underscore";

const StripeCardElement = styled(CardElement)`
    width: 100%;
    background: #353444;
    color: white;
    padding: 12px;
    border-radius: 6px;

    .InputElement{
        color: #fff;
    }
`

const option_style: any = {
    bg: '#262333',
    size: '16px',
    borderWidth: '1px',
    borderColor: '#2F2C3B'
}

const active_option_style: any = {
    ...option_style,
    bg: 'green.700',
    borderWidth: '4px',
    borderColor: 'green.200'
}

interface CreditCardPaymentModalProps extends ButtonProps {
    amount: number;
    subContentIds: Array<number>;
    label?: string;
    onSuccess?: () => void;
}

export const CreditCardPaymentModal = ({ subContentIds: sub_content_ids, amount, onSuccess, label = 'Purchase', ...rest }: CreditCardPaymentModalProps) => {
    const { user, setUser } = useContext(UserContext)

    const [is_loading, setIsLoading] = useState<boolean>()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [credit_cards, setCreditCards] = useState<any[]>([])
    const [provinces, setProvinces] = useState<any[]>([])
    const [is_adding_card, setIsAddingCard] = useState<boolean>()
    const [is_paid, setIsPaid] = useState<boolean>()

    const [name, setName] = useState<string>('')
    const [address_line, setAddressLine] = useState<string>('')
    const [country, setCountry] = useState<any>()
    const [province, setProvince] = useState<any>()
    const [city, setCity] = useState<string>('')
    const [zip_code, setZipCode] = useState<string>('')

    const toast = useToast()
    const stripe = useStripe()
    const elements = useElements()


    useEffect(() => {
        if (user) {
            setName(() => `${user.firstname} ${user.lastname}`)
        }
    }, [user])

    useEffect(() => {
        if (amount) {
            (async () => {
                try{
                    const result = await GetCreditCardListing()
                    if (result && result.length > 0) {
                        result[0].is_selected = true
                    } else {
                        setIsAddingCard(() => true)
                    }
                    console.log(result, 'credit card listing')
                    setCreditCards(() => result)
                }catch(e){
                    setIsAddingCard(() => true)
                }
                
            })();
        }
    }, [amount])

    useEffect(() => {
        if (country) {
            const states = State.getStatesOfCountry(country.value)
            setProvinces(() => states.map(item => ({ label: item.name, value: item.isoCode })))
        }
    }, [country])

    const changeCreditCard = (credit_card: any) => {
        const _credit_cards = [...credit_cards.map(item => item.id === credit_card.id ? { ...item, is_selected: true } : { ...item, is_selected: false })]
        setCreditCards(() => _credit_cards)
    }

    const save = async () => {
        setIsLoading(() => true)
        if (!stripe || !elements) {
            console.log('Stripe.js has not yet loaded.');
            return;
        }

        const address = {
            address: address_line,
            city,
            state: province.value,
            country: country.value,
            postal_code: zip_code
        }

        if (!user.stripe_customer_id) {
            const result = await InitializeAccount(address)
            setUser(() => result)
        }

        const card = elements.getElement(CardElement) as any

        const {address: _address_line, ..._address} = address

        const { paymentMethod: payment_method } = await stripe.createPaymentMethod({
            type: 'card',
            card: card,
            billing_details: {
                name: name,
                address: {..._address, line1: address_line}
            }
        })
        if (!payment_method) {
            console.log(`payment method failed`)
            return;
        }
        const _credit_cards = [...credit_cards.map(item => ({ ...item, is_selected: false }))]
        _credit_cards.push({
            ...payment_method.card,
            is_selected: true,
            id: payment_method.id,
            billing_details: payment_method.billing_details
        })
        setCreditCards(() => _credit_cards)
        setIsAddingCard(() => false)
        setIsLoading(() => false)
    }

    const pay = async () => {
        setIsLoading(() => true)
        const payment_intent = await Initialize(amount * 100)
        const payment_method = findWhere(credit_cards, {
            is_selected: true
        })

        if (!payment_method) {
            console.log('No payment method selected');
            return;
        }

        await Confirm(sub_content_ids, payment_method.id, payment_intent.id)

        setIsLoading(() => false)
        setIsPaid(() => true)
    }

    return (
        <>
            <Button 
                onClick={onOpen} 
                size={'sm'} 
                _hover={{bg: 'green.300'}} 
                color={'gray.800'}
                bg={'green.400'}
                {...rest}>{label}</Button>

            <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'#1F1C2C'}>
                    {!is_paid &&
                        <>
                            <ModalHeader>Secure payment with Stripe</ModalHeader>
                            <ModalCloseButton />
                        </>
                    }

                    <ModalBody pb={'24px'}>
                        {!is_paid &&
                            <VStack spacing={'24px'} align={'start'} w={'full'}>
                                <Text fontWeight={400} fontSize={'14px'} color={'gray.400'}>
                                    Card Debit of  USD ${amount} to Strip Checkout
                                </Text>
                                {is_adding_card &&
                                    <>
                                        <VStack spacing={'6px'} align={'start'} w={'full'}>
                                            <Text color={'gray.300'} fontSize={'14px'}>Name in the card</Text>
                                            <Input value={name} onChange={e => setName(e.target.value)} placeholder={'Enter name in the card'} variant={'filled'} />
                                        </VStack>
                                        <StripeCardElement options={{ hidePostalCode: true, style: {base: {color: '#FFF'}} }} />
                                        <VStack spacing={'6px'} align={'start'} w={'full'}>
                                            <Text color={'gray.300'} fontSize={'14px'}>Address Line</Text>
                                            <Input value={address_line} onChange={e => setAddressLine(e.target.value)} placeholder={'Enter your address line'} variant={'filled'} />
                                        </VStack>
                                        <HStack spacing={'16px'} w={'full'}>
                                            <VStack spacing={'6px'} align={'start'} w={'full'}>
                                                <Text color={'gray.300'} fontSize={'14px'}>Country</Text>
                                                <Select value={country} setValue={country => setCountry(country)} withSearch={true} placeholder={'Select country'} options={[...Country.getAllCountries().map(item => ({ label: item.name, value: item.isoCode }))]} />
                                            </VStack>
                                            <VStack spacing={'6px'} align={'start'} w={'full'}>
                                                <Text color={'gray.300'} fontSize={'14px'}>State</Text>
                                                <Select  withSearch={true} value={province} setValue={province => setProvince(province)} placeholder={'Select state'} options={provinces} />
                                            </VStack>
                                        </HStack>
                                        <HStack spacing={'16px'} w={'full'}>
                                            <VStack spacing={'6px'} align={'start'} w={'full'}>
                                                <Text color={'gray.300'} fontSize={'14px'}>City</Text>
                                                <Input value={city} onChange={e => setCity(e.target.value)} placeholder={'Enter city'} variant={'filled'} />
                                            </VStack>
                                            <VStack spacing={'6px'} align={'start'} w={'full'}>
                                                <Text color={'gray.300'} fontSize={'14px'}>Zip Code</Text>
                                                <Input value={zip_code} onChange={e => setZipCode(e.target.value)} placeholder={'Enter zip code'} variant={'filled'} />
                                            </VStack>
                                        </HStack>
                                    </>
                                }

                                {!is_adding_card &&
                                    <>
                                        <VStack spacing={'6px'} align={'start'} w={'full'}>
                                            <Text color={'gray.300'} fontSize={'14px'}>Select payment option</Text>

                                            <VStack align={'start'} w={'full'}>
                                                {credit_cards.map((item: any, index: number) =>
                                                    <HStack key={index} onClick={() => changeCreditCard(item)} cursor={'pointer'} spacing={'16px'} borderRadius={'8px'} px={'24px'} py={'13px'} w={'full'} bg={'#2F2C3B'}>
                                                        <Center borderRadius={'4px'} w={'52px'} h={'36px'} bg={'white'}>
                                                        { item.brand === 'visa' &&
                                                            <Img h={'100%'} src={'https://img.icons8.com/?size=120&id=dqPuxjOVdLOE&format=png'} />
                                                        }
                                                        { item.brand === 'mastercard' &&
                                                            <Img h={'100%'} src={'https://img.icons8.com/?size=48&id=13610&format=png'} />
                                                        }
                                                        { item.brand === 'discover' &&
                                                            <Img h={'100%'} src={'https://img.icons8.com/?size=48&id=20798&format=png'} />
                                                        }
                                                        { item.brand === 'amex' &&
                                                            <Img h={'100%'} src={'https://img.icons8.com/?size=120&id=tn6rtoc4dcIs&format=png'} />
                                                        }
                                                        { item.brand === 'jcb' &&
                                                            <Img h={'100%'} src={'https://img.icons8.com/?size=48&id=13609&format=png'} />
                                                        }
                                                        { item.brand === 'unionpay' &&
                                                            <Img h={'100%'} src={'https://img.icons8.com/?size=48&id=34350&format=png'} />
                                                        }
                                                        </Center>
                                                        <VStack w={'full'} align={'start'} spacing={0}>
                                                            <Text color={'gray.300'} fontSize={'14px'}>**** **** **** **** {item.last4}</Text>
                                                            <Text color={'gray.300'} fontSize={'14px'}>{('0' + item.exp_month).slice(-2)}/{item.exp_year}</Text>
                                                        </VStack>
                                                        <Circle {...item.is_selected ? active_option_style : option_style} />
                                                    </HStack>
                                                )}
                                                <Text onClick={() => setIsAddingCard(true)} cursor={'pointer'} fontWeight={600} fontSize={'14px'} color={'green.300'}>Add New Card</Text>
                                            </VStack>
                                        </VStack>
                                    </>
                                }

                                <HStack pt={'6px'} w={'full'} spacing={'16px'}>
                                    <Button onClick={onClose} size={'md'} color={'green.400'} w={'50%'}>Cancel</Button>
                                    {is_adding_card &&
                                        <Button onClick={save} isLoading={is_loading} loadingText={`Saving...`} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'} size={'md'} w={'50%'}>Proceed</Button>
                                    }
                                    {!is_adding_card &&
                                        <Button onClick={pay} isLoading={is_loading} loadingText={`Processing...`} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'} size={'md'} w={'50%'}>Proceed</Button>
                                    }
                                </HStack>
                                <HStack fontWeight={400} fontSize={'14px'} color={'gray.400'} justify={'center'} spacing={'8px'} w={'full'}>
                                    <RiSecurePaymentLine />
                                    <Text>Secured by Stripe</Text>
                                </HStack>
                            </VStack>
                        }

                        {is_paid &&
                            <VStack w={'full'} px={'35px'} py={'24px'} spacing={'32px'}>
                                <VStack spacing={'20px'} w={'full'} align={'center'} pt={'24px'}>
                                    <Img h={'80px'} src={'/assets/images/icons/checkmark-circle.png'} />
                                </VStack>
                                <VStack w={'full'} spacing={'4px'}>
                                    <Text fontSize={'2xl'}>Payment Successful</Text>
                                    <Text textAlign={'center'} color={'gray.400'} fontSize={'sm'} fontWeight={400}>
                                        You have successfully completed the purchase. Enjoy the adventure!
                                    </Text>
                                </VStack>
                                <VStack w={'full'} spacing={'16px'}>
                                    <Button onClick={onClose} _hover={{ bg: 'green.300' }} w={'full'} bg={'green.400'} color={'black'}>
                                        Proceed
                                    </Button>
                                </VStack>
                            </VStack>
                        }

                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}