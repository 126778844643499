import { Button, Text, VStack } from "@chakra-ui/react"
import { FaRegCheckCircle } from "react-icons/fa"
import { MainTemplate } from "../../../templates/MainTemplate"
import { useNavigate, useParams } from "react-router-dom"

export const SaveContentSuccessPage = () => {
    const { id } = useParams()
    const navigate = useNavigate()

    return (
        <MainTemplate>
            <VStack pt={'40px'} h={'full'} w={'full'} justify={'center'}>
                <VStack spacing={'32px'} w={'full'} maxW={'588px'} bg={'#1F1C2C'}>
                    <FaRegCheckCircle color={'#2EA652'} size={'80px'} />
                    <VStack spacing={'12px'}>
                        <Text fontSize={'24px'} fontWeight={600}>Success</Text>
                        <Text fontSize={'18px'} fontWeight={400} textAlign={'center'}>
                            Your content submission has been received successfully.
                            Our administrators will review it within the next 24 hours, and if it complies with copyright policies, your work will be published for the public to enjoy.
                            Thank you for sharing your creativity with us!
                        </Text>
                    </VStack>
                    <Button onClick={() => navigate(`/contents/preview/${id}`)} w={'full'} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'}>
                        Proceed
                    </Button>
                </VStack>
            </VStack>
        </MainTemplate>
    )
}