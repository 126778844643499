import { Box, Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, VStack } from "@chakra-ui/react"
import { ReactNode, useContext, useEffect, useState } from "react"
import { Select } from "./Select"
import styled from "@emotion/styled"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { Country, State } from "@flowko/countries-cities-list"
import { UserContext } from "../providers/UserProvider"
import { AddPaymentMethod, InitializeAccount } from "../services/PaymentService"
import { useScreenSize } from "../providers/ScreenSizeProvider"

interface AddPaymentCardModalProps {
    children: ReactNode;
    onSave?: (credit_card: any) => void;
}


const StripeCardElement = styled(CardElement)`
    width: 100%;
    background: #353444;
    padding: 12px;
    border-radius: 6px;

    .InputElement{
        color: white!important;
    }
`

export const AddPaymentCardModal = ({ children, onSave }: AddPaymentCardModalProps) => {
    const { user, setUser } = useContext(UserContext)
    const stripe = useStripe()
    const elements = useElements()

    const { isOpen, onOpen, onClose } = useDisclosure()
    const [name, setName] = useState<string>('')
    const [address_line, setAddressLine] = useState<string>('')
    const [country, setCountry] = useState<any>()
    const [province, setProvince] = useState<any>()
    const [city, setCity] = useState<string>('')
    const [zip_code, setZipCode] = useState<string>('')

    const [provinces, setProvinces] = useState<any[]>([])
    const [is_loading, setIsLoading] = useState<boolean>()
    const { isMobile } = useScreenSize()

    useEffect(() => {
        if (user) {
            setName(() => `${user.firstname} ${user.lastname}`)
        }
    }, [user])

    useEffect(() => {
        if (country) {
            const states = State.getStatesOfCountry(country.value)
            setProvinces(() => states.map(item => ({ label: item.name, value: item.isoCode })))
        }
    }, [country])

    const save = async () => {
        setIsLoading(() => true)
        if (!stripe || !elements) {
            console.log('Stripe.js has not yet loaded.');
            return;
        }

        const address = {
            address: address_line,
            city,
            state: province.value,
            country: country.value,
            postal_code: zip_code
        }

        if (!user.stripe_customer_id) {
            const result = await InitializeAccount(address)
            setUser(() => result)
        }

        const {address: _address_line, ..._address} = address
        const card = elements.getElement(CardElement) as any
        
        const { paymentMethod: payment_method } = await stripe.createPaymentMethod({
            type: 'card',
            card: card,
            billing_details: {
                name: name,
                address: {..._address, line1: address_line}
            }
        })
        if (!payment_method) {
            console.log(`payment method failed`)
            return;
        }

        await AddPaymentMethod(payment_method.id)

        if (onSave) {
            onSave({
                ...payment_method.card,
                id: payment_method.id,
                billing_details: payment_method.billing_details
            })
        }
        setIsLoading(() => false)
        onClose()
    }

    return (
        <>
            <Box onClick={onOpen}>{children}</Box>
            <Modal size={isMobile ? 'full' : 'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent bg={'#1F1C2C'} pb={'24px'}>
                    <ModalHeader>Add Credit/Debit card</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={'24px'} align={'start'} w={'full'}>
                            <VStack spacing={'6px'} align={'start'} w={'full'}>
                                <Text color={'gray.300'} fontSize={'14px'}>Name in the card</Text>
                                <Input value={name} onChange={e => setName(e.target.value)} placeholder={'Enter name in the card'} variant={'filled'} />
                            </VStack>
                            <StripeCardElement options={{ hidePostalCode: true, style: { base: { color: '#FFF' } } }} />
                            <VStack spacing={'6px'} align={'start'} w={'full'}>
                                <Text color={'gray.300'} fontSize={'14px'}>Address Line</Text>
                                <Input value={address_line} onChange={e => setAddressLine(e.target.value)} placeholder={'Enter your address line'} variant={'filled'} />
                            </VStack>
                            <HStack spacing={'16px'} w={'full'}>
                                <VStack spacing={'6px'} align={'start'} w={'full'}>
                                    <Text color={'gray.300'} fontSize={'14px'}>Country</Text>
                                    <Select value={country} setValue={country => setCountry(country)} withSearch={true} placeholder={'Select country'} options={[...Country.getAllCountries().map((item: any) => ({ label: item.name, value: item.isoCode }))]} />
                                </VStack>
                                <VStack spacing={'6px'} align={'start'} w={'full'}>
                                    <Text color={'gray.300'} fontSize={'14px'}>State</Text>
                                    <Select withSearch={true} value={province} setValue={province => setProvince(province)} placeholder={'Select state'} options={provinces} />
                                </VStack>
                            </HStack>
                            <HStack spacing={'16px'} w={'full'}>
                                <VStack spacing={'6px'} align={'start'} w={'full'}>
                                    <Text color={'gray.300'} fontSize={'14px'}>City</Text>
                                    <Input value={city} onChange={e => setCity(e.target.value)} placeholder={'Enter city'} variant={'filled'} />
                                </VStack>
                                <VStack spacing={'6px'} align={'start'} w={'full'}>
                                    <Text color={'gray.300'} fontSize={'14px'}>Zip Code</Text>
                                    <Input value={zip_code} onChange={e => setZipCode(e.target.value)} placeholder={'Enter zip code'} variant={'filled'} />
                                </VStack>
                            </HStack>
                            <HStack pt={'6px'} w={'full'} spacing={'16px'}>
                                <Button onClick={onClose} size={'md'} color={'green.400'} w={'50%'}>Cancel</Button>
                                <Button onClick={save} isLoading={is_loading} loadingText={`Saving...`} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'} size={'md'} w={'50%'}>Proceed</Button>
                            </HStack>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}