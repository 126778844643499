import { Center, HStack, Img, Text, VStack } from "@chakra-ui/react"
import { ContentProps } from "../../../services/ContentService"
import { FiMoreHorizontal } from "react-icons/fi"
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface ContentCardProps {
    content: ContentProps;
}

export const ContentCard = ({ content }: ContentCardProps) => {
    const navigate = useNavigate()
    const view = ()=> {
        if(content.status==='PUBLISHED'){
            navigate(`/contents/${content.code}`)
        }else{
            navigate(`/account/contents/${content.id}`)
        }
    }
    return (
        <VStack onClick={view} cursor={'pointer'} pos={'relative'} spacing={'8px'} align={'start'} w={'180px'} h={'310px'}>
            <HStack overflow={'hidden'} borderTopRightRadius={'8px'} spacing={0} pos={'absolute'} right={0}>
                {content.flag_advisory==='MATURE' &&
                    <Center px={'8px'} h={'22px'} bg={'red.500'}>
                        <Text fontWeight={700} fontSize={'xs'} color={'white'}>18+</Text>
                    </Center>
                }
                <Center px={'8px'} h={'22px'} bg={'green.400'}>
                    <Text fontWeight={700} fontSize={'xs'} color={'black'} textTransform={'capitalize'}>
                        {content.type === 'SINGLE' && 'Single Titled' || content.type}
                    </Text>
                </Center>
            </HStack>
            <Center w={'full'} h={'255px'} borderRadius={'8px'} overflow={'hidden'} bg={'#000'}>
                <Img src={content.thumbnail_url} />
            </Center>
            <VStack align={'start'} w={'full'} spacing={0}>
                <HStack justify={'space-between'} w={'full'}>
                    <Text fontWeight={600}>{content.title}</Text>
                    <FiMoreHorizontal />
                </HStack>
                <Text fontSize={'12px'} color={'gray.300'}>
                    { moment(content.created_at).format('DD/MM/YYYY') }
                </Text>
            </VStack>
        </VStack>
    )
}