import { Box, Button, Text, VStack } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { MainTemplate } from "../../../templates/MainTemplate"
import { FaRegCheckCircle } from "react-icons/fa"
import { useEffect } from "react"
import { ConfirmPayoutAccount } from "../../../services/PaymentService"

export const CreatePayoutAccountSuccess = () => {
    const navigate = useNavigate();

    useEffect(()=> {
        (async ()=> {
            await ConfirmPayoutAccount()
        })();
    }, [])

    return (
        <MainTemplate>
            <VStack pt={'40px'} h={'full'} w={'full'} justify={'center'}>
                <VStack spacing={'32px'} w={'full'} maxW={'588px'} bg={'#1F1C2C'}>
                    <FaRegCheckCircle color={'#2EA652'} size={'80px'} />
                    <VStack spacing={'12px'}>
                        <Text fontSize={'24px'} fontWeight={600}>Success</Text>
                        <Text fontSize={'18px'} fontWeight={400} textAlign={'center'}>
                            Congratulations! You just successfully setup your payout account. 
                            You can start selling your contents and cashing out your earnings, Good luck!
                        </Text>
                    </VStack>
                    <Button onClick={() => navigate(`/account/wallet`)} w={'full'} px={'24px'} _hover={{ bg: 'green.300' }} bg={'green.400'} color={'black'}>
                        Proceed
                    </Button>
                </VStack>
            </VStack>
        </MainTemplate>
    )
}