import { Avatar, Box, Button, Center, CenterProps, HStack, IconButton, Img, StackProps, Tab, TabList, TabPanel, TabPanels, Tabs, Text, VStack } from "@chakra-ui/react";
import { BsChatSquare, BsDownload, BsStar } from "react-icons/bs";
import { FiBookmark, FiFlag, FiShare2, FiThumbsUp, FiX } from "react-icons/fi";
import { RiUserLine } from "react-icons/ri";
import { ContentProps, SubContentProps } from "../services/ContentService";
import { useNavigate } from "react-router-dom";
import { CreditCardPaymentModal } from "./CreditCardPaymentModal";
import { ContentPurchaseSuccessModal } from "./ContentPurchaseSuccessModal";
import { useState } from "react";

interface ContentDetailsCardProps {
    content: ContentProps;
    hasHeader?: boolean;
    hasRightButtons?: boolean;
    width?: string | number;
    hasClose?: boolean;
    onClose?: () => void;
}

const tag_style: CenterProps = {
    borderRadius: '8px',
    bg: '#2F2C3B',
    px: '18px',
    py: '12px',
    fontSize: '12px',
    fontWeight: 400,
    cursor: 'pointer',
    textTransform: 'capitalize'
}


export const ContentDetailsCard = ({ hasClose, onClose, hasRightButtons, hasHeader, content, width = '755px' }: ContentDetailsCardProps) => {
    const navigate = useNavigate()
    
    const viewIssue = (sub_content: SubContentProps)=> {
        navigate(`/contents/read/${content.id}/${sub_content.id}`)
    }

    const onPurchase = ()=> {

    }

    return (
        <VStack spacing={'24px'} padding={'24px'} bg={'#262333'} borderRadius={'8px'} w={width}>
            {hasHeader &&
                <>
                    <HStack w={'full'} justify={'space-between'} align={'start'}>
                        <VStack spacing={0} align={'start'}>
                            <Text fontSize={'24px'} fontWeight={600}>{content.title}</Text>
                            {hasClose &&
                                <Center fontSize={'14px'} fontWeight={600} color={'gray.200'} borderRadius={'8px'} py={'6px'} px={'16px'} bg={'rgb(255, 255, 255, 0.04)'}>
                                    {content.is_paid ? `${content.price} USD` : 'Free'}
                                </Center>
                            }

                        </VStack>
                        {!hasClose &&
                            <Center fontSize={'14px'} fontWeight={600} color={'gray.200'} borderRadius={'8px'} py={'6px'} px={'16px'} bg={'rgb(255, 255, 255, 0.04)'}>
                                {content.is_paid ? `${content.price} USD` : 'Free'}
                            </Center>
                        }

                        {hasClose &&
                            <IconButton onClick={onClose} aria-label={'Close'} icon={<FiX />} />
                        }

                    </HStack>
                    <HStack justify={'start'} w={'full'} spacing={'8px'}>
                        {content.owner.profile_picture ?
                            <Avatar borderRadius={'4px'} src={content.owner.profile_picture} size={'xl'} />
                            :
                            <Center borderRadius={'8px'} borderWidth={'3px'} borderColor={'white'} h={'80px'} w={'80px'}>
                                <RiUserLine size={'32px'} color={'white'} />
                            </Center>
                        }
                        <VStack align={'start'} spacing={0}>
                            <Text color={'gray.300'}>Author</Text>
                            <Text textDecoration={'underline'} color={'gray.200'} fontWeight={600}>{content.owner.firstname} {content.owner.lastname}</Text>
                        </VStack>
                    </HStack>
                    <HStack w={'full'} justify={'space-between'}>
                        <HStack spacing={'12px'}>
                            <HStack spacing={'4px'}>
                                <IconButton aria-label={'Like'} icon={<FiThumbsUp />} />
                                <Text fontWeight={'16px'} color={'gray.200'}>0</Text>
                            </HStack>
                            <HStack spacing={'4px'}>
                                <IconButton aria-label={'Stars'} icon={<BsStar />} />
                                <Text fontWeight={'16px'} color={'gray.200'}>4.5 (120)</Text>
                            </HStack>
                        </HStack>
                        {hasRightButtons &&
                            <HStack spacing={'12px'}>
                                <IconButton aria-label={'Bookmark'} icon={<FiBookmark />} />
                                { content.is_download_available &&
                                    <IconButton aria-label={'Bookmark'} icon={<BsDownload />} />
                                }
                                <IconButton aria-label={'Bookmark'} icon={<FiShare2 />} />
                                <IconButton aria-label={'Bookmark'} icon={<FiFlag />} />
                            </HStack>
                        }

                    </HStack>
                </>
            }
            <Tabs pt={'32px'} colorScheme={'green'} w={'full'}>
                <TabList>
                    <Tab fontSize={'14px'}>
                        Overview
                    </Tab>
                    {(content.type === 'SERIES' && content.sub_contents) &&
                        <Tab fontSize={'14px'}>
                            Issues ({content.sub_contents.length})
                        </Tab>
                    }

                    {hasRightButtons &&
                        <HStack position={'absolute'} right={0} marginTop={'-8px'} marginRight={'8px'}>
                            {/* <Button size={'sm'} color={'green.400'}>Donate</Button> */}
                            <Button onClick={()=> navigate(`/contents/read/${content.id}`)} size={'sm'} color={'green.400'}>Read Free pages</Button>
                            { (content.is_paid && content.sub_contents && content.sub_contents.length > 0) && 
                                <CreditCardPaymentModal subContentIds={[content.sub_contents[0].id as number]} amount={content.price as number} onSuccess={onPurchase} />
                            }
                            <ContentPurchaseSuccessModal />
                        </HStack>
                    }
                </TabList>
                <TabPanels>
                    <TabPanel as={VStack} align={'start'} px={0} spacing={'24px'}>
                        <VStack align={'start'}>
                            <Text fontSize={'14px'} color={'gray.300'}>Brief Description</Text>
                            <Text fontSize={'12px'} color={'gray.300'}>
                                {content.description}
                            </Text>
                        </VStack>
                        <VStack spacing={'16px'} align={'start'} w={'full'}>
                            <Box borderTop={'1px solid #1F1C2C'} width={'full'} />
                            <Text fontSize={'14px'} color={'gray.300'}>Genre</Text>
                            <HStack>
                                {content.genres.map((item, index) =>
                                    <Center key={index} {...tag_style}>{item}</Center>
                                )}
                            </HStack>
                        </VStack>
                        <VStack spacing={'16px'} align={'start'} w={'full'}>
                            <Box borderTop={'1px solid #1F1C2C'} width={'full'} />
                            <Text fontSize={'14px'} color={'gray.300'}>Tags</Text>
                            <HStack>
                                {content.tags.map((item, index) =>
                                    <Center key={index} {...tag_style}>{item}</Center>
                                )}
                            </HStack>
                        </VStack>
                    </TabPanel>
                    <TabPanel as={VStack} align={'start'} px={0} spacing={'24px'}>
                        {(content.sub_contents && content.sub_contents.length > 0) && content.sub_contents.map((item: any, index: number) =>
                            <HStack key={index} w={'full'} justify={'space-between'} onClick={()=> viewIssue(item)} cursor={'pointer'}>
                                { item.thumbnail_url ?
                                    <Center borderRadius={'4px'} bg={'#353444'} w={'60px'} h={'60px'}>
                                        <Img maxWidth={'full'} maxHeight={'full'} src={item.thumbnail_url} />
                                    </Center>
                                    :
                                    <Center borderRadius={'4px'} p={'4px'} bg={'#353444'} w={'60px'} h={'60px'}>
                                        <Img maxWidth={'full'} maxHeight={'full'} src={'/assets/images/logo-vertical.png'} />
                                    </Center>
                                }
                                
                                <VStack spacing={0} align={'start'} w={'520px'}>
                                    <Text color={'gray.200'} fontSize={'12px'} fontWeight={600}>{item.title ? item.title: 'Untitled Issue'}</Text>
                                    <Text color={'gray.300'} fontSize={'12px'} fontWeight={400}>{item.description ? item.description: 'No brief description set for this issue'}</Text>
                                </VStack>
                                <Text color={'gray.200'} fontSize={'12px'} fontWeight={600}>12/05/2023</Text>
                                <Text w={'40px'} color={'gray.200'} fontSize={'12px'} fontWeight={600}>{ item.is_paid ? 'Unlock': 'Free'}</Text>
                            </HStack>
                        )}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </VStack>
    )
}