import { Box, Button, Circle, Divider, HStack, IconButton, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiChevronDown, FiSearch, FiX } from "react-icons/fi";
import { useDebounce } from 'use-debounce';

interface OptionProps {
    label: string;
    subLabel?: string;
    value: string | any;
}

interface SelectProps {
    options: OptionProps[];
    value?: OptionProps;
    setValue?: (value: OptionProps) => void;
    placeholder?: string;
    subPlaceholder?: string;
    offset?: [number, number];
    width?: string;
    withSearch?: boolean;
    maxDisplay?: number;
}

const option_style: any = {
    bg: '#262333',
    size: '16px',
    borderWidth: '1px',
    borderColor: '#2F2C3B'
}

const active_option_style: any = {
    ...option_style,
    bg: 'green.700',
    borderWidth: '4px',
    borderColor: 'green.200'
}

export const Select = ({ 
            options, 
            value, 
            setValue, 
            placeholder = 'Select options', 
            subPlaceholder = placeholder, 
            offset = [12, 12], 
            width = '285px', 
            withSearch, 
            maxDisplay = 10
        }: SelectProps) => {

    const [keyword, setKeyword] = useState<string>('')
    const [debounced_keyword] = useDebounce(keyword, 1000);
    const [local_options, setLocalOptions] = useState<OptionProps[]>([])

    useEffect(() => {
        let _options = [...options]
        if (debounced_keyword) {
            _options = _options.filter(item => {
                return item.label.toLowerCase().indexOf(debounced_keyword.toLowerCase()) > -1 ||
                item.value.toLowerCase().indexOf(debounced_keyword.toLowerCase()) > -1
            })
        }
        const _local_options = [..._options]
        setLocalOptions(() => _local_options)
    }, [debounced_keyword, options])

    const changeValue = (option: OptionProps) => {
        if (setValue) {
            setValue(option)
        }
    }

    const add = ()=> {
        const value = {
            label: debounced_keyword,
            value: debounced_keyword.toUpperCase().replaceAll(' ',  '_')
        }
        changeValue(value)
        setKeyword(()=> '')
    }

    return (
        <Menu isLazy={true} offset={offset} placement={'top'}>
            <MenuButton color={value ? 'white' : 'gray.400'} fontWeight={400} textAlign={'left'} w={'full'} as={Button} rightIcon={<FiChevronDown />}>
                {value ? value.label : placeholder}
            </MenuButton>
            <MenuList bg={'#1F1C2C'} w={width}>
                <HStack px={'12px'} justify={'space-between'}>
                    <Text fontWeight={600}>{subPlaceholder}</Text>
                    <IconButton size={'sm'} aria-label={'Close'} icon={<FiX />} />
                </HStack>
                <Box py={'8px'}>
                    { withSearch ?
                        <InputGroup>
                            <Input value={keyword} onChange={e => setKeyword(e.target.value)} borderRadius={0} variant={'filled'} type='text' placeholder='Search' />
                            <InputRightElement>
                                <FiSearch />
                            </InputRightElement>
                        </InputGroup>
                        :
                        <Divider />
                    }
                </Box>
                <Box maxH={'300px'} overflowY={'auto'}>
                    {local_options.map((item: any, index: number) =>
                        <MenuItem onClick={() => changeValue(item)} key={index} py={'12px'} cursor={'pointer'} alignItems={'center'} as={HStack} w={'full'} justify={'space-between'} bg={'#1F1C2C'}>
                            <VStack spacing={0} align={'start'}>
                                <Text>{item.label}</Text>
                                {item.subLabel &&
                                    <Text color={'gray.400'} fontSize={'12px'}>{item.subLabel}</Text>
                                }
                            </VStack>
                            <Circle {...(value && value.value === item.value) ? active_option_style : option_style} />
                        </MenuItem>
                    )}
                </Box>
                { (debounced_keyword && local_options.length === 0) &&
                    <MenuItem justify={'space-between'} as={HStack} w={'full'} bg={'#1F1C2C'}>
                       <Text textTransform={'capitalize'}>{ debounced_keyword }</Text>
                       <Button onClick={add} size={'sm'}>Add</Button>
                    </MenuItem>
                }
            </MenuList>
        </Menu>
    )
}