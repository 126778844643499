import { useContext, useEffect, useState } from "react"
import { UserContext } from "../../../providers/UserProvider"
import { MainTemplate } from "../../../templates/MainTemplate"
import { Button, Center, CenterProps, HStack, Img, Input, InputGroup, InputLeftElement, Select, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from "@chakra-ui/react"
import { RequestPayoutModal } from "../components/RequestPayoutModal"
import { RequestPayout } from "../../../services/PaymentService"
import { ContentStatsCard } from "../components/ContentStatsCard"
import { FiSearch } from "react-icons/fi"
import { RiStackLine } from "react-icons/ri"
import { GetListing } from "../../../services/TransactionService"
import moment from "moment"
import { ConfirmConnectPayoutDialog } from "../components/ConfirmConnectPayoutDialog"
import { GetCurrent } from "../../../services/UserService"

const badge_style: CenterProps = {
    fontSize: '12px',
    fontWeight: 600,
    borderRadius: '16px',
    px: '8px',
    py: '4px',
    bg: '#3D3C4B'
}


export const WalletPage = () => {
    const { user, setUser } = useContext(UserContext)
    const [transactions, setTransactions] = useState<Array<any>>([])
    const [refresh_time, setRefreshTime] = useState<Date>(new Date())

    const sendPayoutRequest = async () => {
        const user = await GetCurrent()
        setUser(()=> user)
        setRefreshTime(() => new Date())
    }

    useEffect(() => {
        (async () => {
            const result = await GetListing()
            setTransactions(() => result)
        })()
    }, [refresh_time]);

    const getColorScheme = (status: string) => {
        let color = 'white'
        if (status === 'SUCCESS') {
            color = 'green.500'
        }
        if (status === 'PENDING') {
            color = 'yellow.400'
        }
        if (status === 'ERROR') {
            color = 'RED.400'
        }
        return color
    }

    const onCreateAccount = (stripe_payout_account: any)=> {
        window.location.href = stripe_payout_account.onboarding_link
    }

    return (
        <MainTemplate>
            <VStack w={'full'} align={'center'}>
                <VStack spacing={'24px'} align={'start'} w={'1160px'}>
                    <HStack w={'full'} justify={'space-between'}>
                        <Text fontSize={'24px'}>My Wallet</Text>
                        { (user && user.payout_account_id) &&
                            <RequestPayoutModal onSendRequest={sendPayoutRequest} />
                            ||
                            <ConfirmConnectPayoutDialog onSave={onCreateAccount} />
                        }
                    </HStack>
                    <HStack spacing={'24px'} align={'start'}>
                        <VStack w={'full'} spacing={'32px'}>
                            {user &&
                                <HStack spacing={'24px'}>
                                    <ContentStatsCard w={'415px'} type="EARNINGS" value={user.earnings ? user.earnings : 0} stats={[]} />
                                    <ContentStatsCard w={'415px'} type="PAYOUTS" value={user.payouts ? user.payouts : 0} stats={[]} />
                                </HStack>
                            }
                            <VStack w={'full'} spacing={'16px'}>
                                <HStack w={'full'}>
                                    <Text flexGrow={1}
                                        color={'gray.200'}
                                        fontWeight={600}
                                        fontSize={'18px'}>
                                        Transaction History
                                    </Text>
                                    <InputGroup w={'236px'}>
                                        <InputLeftElement pointerEvents='none'>
                                            <FiSearch />
                                        </InputLeftElement>
                                        <Input variant={'filled'} type={'text'} placeholder='Search' />
                                    </InputGroup>
                                    <Select w={'180px'} variant={'filled'}>
                                        <option>All Transactions</option>
                                    </Select>
                                    <Button px={'24px'} leftIcon={<RiStackLine />} colorScheme={'gray'} color={'white'}>Sort by</Button>
                                </HStack>
                                <TableContainer borderRadius={'8px'} bg={'#262333'} w={'full'}>
                                    <Table variant='simple' w={'full'}>
                                        <Thead>
                                            <Tr bg={'#353444'}>
                                                <Th>Transaction Type</Th>
                                                <Th>Date</Th>
                                                <Th>Amount</Th>
                                                <Th>Note</Th>
                                                <Th>Status</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {transactions.map((item: any, index: number) =>
                                                <Tr key={index}>
                                                    <Td>
                                                        <Text style={{ textWrap: 'pretty' }}
                                                            fontSize={'12px'}
                                                            textTransform={'capitalize'}
                                                            w={'140px'}>
                                                            {item.type.replaceAll('_',' ').toLowerCase()}
                                                        </Text>
                                                    </Td>
                                                    <Td>
                                                        <Text style={{ textWrap: 'pretty' }}
                                                            fontSize={'12px'}
                                                            textTransform={'capitalize'}
                                                            w={'140px'}>
                                                            {moment(item.date).format('MM-DD-YYYY')}
                                                        </Text>
                                                    </Td>
                                                    <Td>
                                                        <Text style={{ textWrap: 'pretty' }}
                                                            fontSize={'12px'}
                                                            textTransform={'capitalize'}
                                                            w={'140px'}>
                                                            ${item.amount}
                                                        </Text>
                                                    </Td>
                                                    <Td>
                                                        <Text style={{ textWrap: 'pretty' }}
                                                            fontSize={'12px'}
                                                            textTransform={'capitalize'}
                                                            w={'140px'}>
                                                            {item.note ? item.note : 'NA'}
                                                        </Text>
                                                    </Td>
                                                    <Td>
                                                        <Center {...badge_style} color={getColorScheme(item.status)}>
                                                            <Text textTransform={'capitalize'}>{item.status.replace(`_`, ` `).toLowerCase()}</Text>
                                                        </Center>
                                                    </Td>
                                                </Tr>
                                            )}
                                        </Tbody>
                                    </Table>
                                    <HStack justify={'space-between'} w={'full'} p={'16px'}>
                                        <Button size={'sm'} variant={'outline'}>Previous</Button>
                                        <HStack>
                                            <Button size={'sm'}>1</Button>
                                            <Button size={'sm'}>2</Button>
                                            <Button size={'sm'}>...</Button>
                                            <Button size={'sm'}>10</Button>
                                        </HStack>
                                        <Button size={'sm'} variant={'outline'}>Next</Button>
                                    </HStack>
                                </TableContainer>
                            </VStack>
                        </VStack>
                        <Img w={'280px'} src={'/assets/images/right-wallet-placeholder.png'} />
                    </HStack>
                </VStack>
            </VStack>
        </MainTemplate>
    )
}