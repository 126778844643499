import { Button, HStack, Img, Input, Link, Text, useToast, VStack } from "@chakra-ui/react"
import { AuthTemplate } from "../../../templates/AuthTemplate"
import { useNavigate } from "react-router-dom"
import { Register, RegisterReqProps } from "../../../services/AuthService"
import { useState } from "react"
import { useScreenSize } from "../../../providers/ScreenSizeProvider"
export const RegisterByEmailPage = () => {
    const navigate = useNavigate()
    const toast = useToast()
    const [email_error, setEmailError] = useState<string>()
    const [user, setUser] = useState<RegisterReqProps>({
        firstname: '',
        lastname: '',
        email: '',
        password: ''
    })
    const [is_registering, setIsRegistering] = useState<boolean>()
    const { isMobile } = useScreenSize()

    const isValid = () => {
        return user.email && user.password
    }

    const register = async () => {
        setIsRegistering(() => true)
        try {
            const { token } = await Register(user)
            navigate(`/register/verify/${token}`)
        } catch (e: any) {
            switch (e.cause) {
                case 1:
                    setEmailError(()=> e.message)
                    break;
                default:
                    toast({
                        status: `error`,
                        description: e.message,
                        duration: 3000,
                        position: 'bottom-right'
                    })
                    break;
            }

        }
        setIsRegistering(() => false)
    }
    return (
        <AuthTemplate>
            <VStack spacing={'32px'}
                align={'start'}
                px={'24px'}
                py={!isMobile ? '20px' : '60px'}
                w={!isMobile ? '500px' : 'full'}
                h={!isMobile ? '600px' : 'full'}
                minH={'500px'}
                bg={'#262333'}
                border={'1px solid #2F2C3B'}
                borderRadius={'8px'}>
                <VStack spacing={'20px'} w={'full'} align={'start'}>
                    <Img h={'50px'} cursor={'pointer'} onClick={() => navigate(`/`)} src={'/assets/images/logo-vertical.png'} />
                    <VStack align={'start'} w={'full'} spacing={'4px'}>
                        <Text fontWeight={600} fontSize={'2xl'}>Create your account</Text>
                        <Text fontSize={'sm'} fontWeight={400} color={'gray.400'}>Please be aware that email verification may be necessary for completing sign up.</Text>
                    </VStack>
                </VStack>
                <VStack w={'full'} spacing={'16px'}>
                    <HStack alignSelf={'stretch'}>
                        <Input value={user.firstname} onChange={e => setUser({ ...user, firstname: e.target.value })} variant={'filled'} fontSize={'sm'} size={'lg'} placeholder={'First Name'} />
                        <Input value={user.lastname} onChange={e => setUser({ ...user, lastname: e.target.value })} variant={'filled'} fontSize={'sm'} size={'lg'} placeholder={'Last Name'} />
                    </HStack>
                    <VStack w={'full'} align={'start'} spacing={'2px'}>
                        <Input borderWidth={email_error ? '1px': 'none'} borderColor={email_error ? 'red.200': 'transparent'} value={user.email} onChange={e => setUser({ ...user, email: e.target.value })} variant={'filled'} fontSize={'sm'} size={'lg'} placeholder={'Email Address'} />
                        <Text fontSize={'sm'} color={'red.200'}>{email_error}</Text>
                    </VStack>
                    <Input value={user.password} onChange={e => setUser({ ...user, password: e.target.value })} variant={'filled'} fontSize={'sm'} size={'lg'} placeholder={'Password'} type={'password'} />
                    <Button isLoading={is_registering} loadingText={`Creating Account...`} isDisabled={!isValid()} onClick={register} _hover={{ bg: 'green.300' }} w={'full'} bg={'green.400'} color={'black'}>
                        Proceed
                    </Button>
                    <HStack align={'center'} justify={'center'} w={'full'}>
                        <Text fontSize={'sm'}>Already have an account?</Text>
                        <Link fontWeight={600} onClick={() => navigate('/login')}>Login</Link>
                    </HStack>
                </VStack>
                <HStack justify={'center'} w={'full'}>
                    <Text fontSize={'sm'} w={'312px'} textAlign={'center'}>
                        By signing up, you agree to Novatoons' <Text fontWeight={600} as={'span'}>Terms of Service and Privacy Policy</Text>
                    </Text>
                </HStack>
            </VStack>
        </AuthTemplate>
    )
}